
$(document).ready(function() {


  var test = false;
  var effect = false;
  var welcomeEffect = true;
  var height = $(window).height();
  var kram = 0;

  $.each( $( ".testtest" ), function() {
    if($(this).outerHeight() >= kram) {
      kram = $(this).outerHeight();
    }
  });

  // $('.testtest').css('max-height',kram);
  // $('.testtest').css('min-height',kram);
  // $('.testtest').css('padding-top','20vh');


  // if(welcomeEffect) {
  //   $(window).scroll(function(e){
  //     var distancetowelcome1 = $(".welcome-text-1").offset().top - $(window).scrollTop();
  //     if(distancetowelcome1 <= height/1.2) {
  //       TweenMax.to('.welcome-text-1', 1.8, {paddingTop:'15px',opacity:1});
  //     }
  //     var distancetowelcome2 = $(".welcome-text-2").offset().top - $(window).scrollTop();
  //     if(distancetowelcome2 <= height/1.2) {
  //       TweenMax.to('.welcome-text-2', 1.8, {paddingTop:'15px',opacity:1});
  //       welcomeEffect = false;
  //     }
  //   });
  // }


  $('#go').click(function(){
      var $anchor = $(this);
      // ANIMATE TO ANCHOR FUNCTION
      $("html,body").animate({scrollTop: $( window ).height()}, 1000);
  });

  $('#button').click(function(){
    $('html, body').animate({ scrollTop: 0 }, 3500);
  });

  $( "#test" )
  .mouseenter(function() {
    test = true;
  })
  .mouseleave(function() {
    test = false;
    effect = false;
    $('.effect').css('color','#fff');
  });


  $( ".effect" )
  .mouseenter(function() {
    if(test && !effect) {
      $(this).siblings().css('color','#aaa');
    } else {
      $(this).siblings().css('color','#aaa');
      $(this).css('color','#fff');
    }
  })
  .mouseleave(function() {
    effect = true;
  });



  var tl = new TimelineMax();
  tl.from("#secondHeadline",5,{x:'+100%'})
    .from("#firstHeadline",5,{x:'+200%'},3)
    .to($('#car'),12,{x:"-2000px"},5);
});
